import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import socketIOClient from "socket.io-client";
import NumberFormat from "react-number-format";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Axios from "axios";
import "./Details.css";
import {
  Row,
  Col,
  Button,
  Modal,
  Tag,
  Timeline,
  message,
  Spin,
  Popover,
  Avatar,
  DatePicker,
  TimePicker,
  Select,
  Typography,
  Alert,
  Switch,
  Tooltip,
  notification,
} from "antd";
import { Form, Input } from "formik-antd";
import { Formik } from "formik";
import {
  CalendarOutlined,
  HomeOutlined,
  UserOutlined,
  FileDoneOutlined,
  EditOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  UserSwitchOutlined,
  CarOutlined,
  CloseOutlined,
  CheckOutlined,
  CopyOutlined,
  CrownOutlined,
} from "@ant-design/icons";
import {
  renderLabel,
  renderSection,
  eventDateFormat,
  convertISODateString,
  formatUsAddress,
  convertISOStrToDate,
  statusTagColor,
  verifyAddress,
} from "../../../../shared/utils/utils";
import Can from "../../../../user/Component/Auth/Can";
import Notes from "../../Order/Common/Notes";
import moment from "moment";
import { AssignPhotoGrapher } from "../../Order/Common/AssignPhotoGrapher";
import InvoiceList from "./InvoiceList";
import { PreviewMap } from "../../Order/Common/PreviewMap";
import { updatePhotographerCalc } from "../../../../shared/payBreakdown/photographersPayCalc";
import ability from "../../../../user/Component/Auth/ability";
import Invoice from "../../Invoices/Invoice";
import TripChargeCalc from "./../../../../shared/Components/TripChargeCalc";
import TwilightIcon from "../../../../content/icons/Twilight.svg";
import { notifyClientEdit } from "../../../../redux/action/clientAction";
import { connect } from "react-redux";
import SendEmail from "../../Order/Common/SendEmail";
import { updateWebSocketNote } from "../../../../redux/action/note";
import { updateWebSocketShoot } from "../../../../redux/action/shootAction";
import CreateInvoiceButton from "../../Invoices/CreateInvoiceButton";

const { Option } = Select;
const { Paragraph } = Typography;
const Details = ({
  auth,
  match,
  selectedShoot,
  update,
  activities,
  auth: { user },
  photographer: { currentPhotographer, photographers, selectedPhotographers },
  setSelectedPhotographer,
  updateInvoice,
  createCredit,
  createActivity,
  offerings,
  invoice: { selectedInvoice },
  fetchShootById,
  fetchInvoiceById,
  notifyClientEdit,
  fetchPaymentsByInvoiceId,
  payments,
  updateWebSocketNote,
  updateWebSocketShoot,
  allPhotographerUnavailSlotsForDay,
  fetchAllPhotoGraphersUnAvailabilityForADay,
}) => {
  const [modal, setModal] = useState({
    editModal: false,
    invModal: false,
  });
  const [unavailablePhotographers, setUnavailablePhotographers] = useState([]);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [restoreModalVisible, setRestoreModalVisible] = useState(false);
  const [updatedNote, setUpdatedNote] = useState();
  const [updatedShoot, setUpdatedShoot] = useState();
  const [restoreReason, setRestoreReason] = useState("None given.");
  const [cancelReason, setCancelReason] = useState("None given.");
  let driveT;
  const [sectionTitle, setSectionTitle] = useState("");
  const [selectedPhtgr, setSelectedPhtgr] = useState(null);
  const [flex, setFlex] = useState(selectedShoot.flexible);
  const [loader, setLoader] = useState(false);
  const [hasCredit, setHasCredit] = useState(false);
  const [hasDiscount, setHasDiscount] = useState(false);
  const APPT_DTLS = "Appointment Details";
  const SCHD_DTLS = "Schedule Details";
  const PRTY_DTLS = "Property Details";
  const CLNT_DTLS = "Client Details";
  const SC_DTLS = "Secondary Contact Details";
  const [formData, setFormData] = useState({});
  const [isSaturdayShoot, setIsSaturdayShoot] = useState(false);
  const [date, setDate] = useState(
    selectedShoot.events.start
      ? convertISOStrToDate(selectedShoot.events?.start)
      : new Date()
  );
  const [time, setTime] = useState({
    start: selectedShoot.events.start
      ? convertISOStrToDate(selectedShoot.events?.start)
      : new Date(),
    end: selectedShoot.events.end
      ? convertISOStrToDate(selectedShoot.events?.end)
      : convertISOStrToDate(
          convertISODateString(
            new Date(
              new Date().setMinutes(
                new Date().getMinutes() + selectedShoot.events?.duration
              )
            )
          )
        ),
  });
  const [manualFetch, setManualFetch] = useState(false);
  const [reset, setReset] = useState(false);
  const { start, end } = time;
  const { invModal, editModal } = modal;
  let history = useHistory();
  const { pathname } = history.location;
  useEffect(() => {
    if (
      selectedShoot._id === pathname.split("/")[3] &&
      !Object.keys(formData).length
    ) {
      setFormData({
        [PRTY_DTLS]: {
          address: { ...selectedShoot.address },
        },
        [SCHD_DTLS]: {
          events: { ...selectedShoot.events },
          status: selectedShoot.status,
        },
        [CLNT_DTLS]: {
          client_name_first: selectedShoot?.client_name_first,
          client_name_last: selectedShoot?.client_name_last,
          client_email: selectedShoot?.client_email,
          client_phone: selectedShoot?.client_phone,
        },
        [APPT_DTLS]: {
          booking_form: { ...selectedShoot.booking_form },
        },
        [SC_DTLS]: {
          booking_form: { ...selectedShoot.booking_form },
        },
      });
    }
  }, [selectedShoot]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    let socket;
    const UserId = user?._id;
    if (UserId) {
      socket = socketIOClient.connect(process.env.REACT_APP_WS_URL, {
        transports: ["websocket"],
      });
      socket.on(`currentNote-${selectedShoot._id}-notes`, (data) => {
        setUpdatedNote(data);
      });
      socket.on(`shoot-${selectedShoot._id}-updated`, (data) => {
        setUpdatedShoot(data);
        notify();
      });
    }
    return () => {
      if (socket) {
        socket.disconnect(UserId);
        console.log("Socket disconnected!");
      }
    };
  }, []);

  const notify = () => {
    notification.open({
      message: "Shoot updated",
      description: "This is shoot has been updated. Please refresh the page.",
      placement: "bottomRight",
    });
  };

  useEffect(() => {
    const unavailablePhgraphers = [];
    allPhotographerUnavailSlotsForDay.forEach((photographerUnavail) => {
      if (
        convertISOStrToDate(photographerUnavail.date).toDateString() ===
        date.toDateString()
      ) {
        const startTime = new Date(
          date.toDateString() + " " + time.start.toTimeString()
        );
        photographerUnavail.unAvailableSlots.some((slot) => {
          //if start time is within the slot add this photographer as unavailable.
          const start = convertISOStrToDate(slot.start);
          const end = convertISOStrToDate(slot.end);
          if (startTime > start && startTime < end) {
            unavailablePhgraphers.push(photographerUnavail.photographer);
            return true;
          }
          return false;
        });
      }
    });
    setUnavailablePhotographers(unavailablePhgraphers);
  }, [allPhotographerUnavailSlotsForDay, time]);

  useEffect(() => {
    fetchAllPhotoGraphersUnAvailabilityForADay(date.toISOString());
  }, []);

  useEffect(() => {
    if (updatedNote) {
      updateWebSocketNote(updatedNote);
    }
  }, [updatedNote]);

  useEffect(() => {
    if (updatedShoot) {
      updateWebSocketShoot(updatedShoot);
    }
  }, [updatedShoot]);

  useEffect(() => {
    return () => {
      setSelectedPhotographer(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFlex(selectedShoot.flexible);
    return () => {
      setFlex(selectedShoot.flexible);
    };
  }, [selectedShoot]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedInvoice?._id && manualFetch) {
      fetchShootById(match?.params?.id);
      setManualFetch(false);
    }
  }, [selectedInvoice, manualFetch, updateInvoice, invModal]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (selectedInvoice?._id) {
      fetchShootById(match?.params?.id);
    }
  }, [invModal]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchShootById(match?.params?.id);
  }, [selectedPhtgr]); // eslint-disable-line react-hooks/exhaustive-deps

  const directionsUrl = (shoot) => {
    if (currentPhotographer && currentPhotographer.home_address) {
      return `https://www.google.com/maps/dir/?api=1&origin=${currentPhotographer?.home_address}&destination=${shoot?.address?.full}&travelmode=driving`;
    } else {
      return `https://www.google.com/maps/dir/?api=1&destination=${shoot?.address?.full}&travelmode=driving`;
    }
  };

  const handleOk = (type) => {
    setModal({
      ...modal,
      [type]: false,
    });
  };

  const handleCancel = (type) => {
    setModal({
      ...modal,
      [type]: false,
    });
    setHasCredit(false);
    setHasDiscount(false);
  };

  const handleAddressConfirm = async () => {
    const res = await update(selectedShoot._id, {
      ...selectedShoot,
      address: {
        ...selectedShoot.address,
        verify: false,
        verified: true,
      },
    });
    if (res.status === 200) {
      createActivity(selectedShoot, "Address was confirmed.");
      message.success({
        content: "Address confirmed!",
        style: {
          marginTop: "10vh",
        },
      });
    } else {
      message.error("Oops! Error occured while confirming address");
    }
  };

  const onChangeDate = (date, values, setFieldValue) => {
    const saturdayShoot = isSaturday(date._d);
    if (saturdayShoot) {
      setIsSaturdayShoot(true);
    } else {
      setIsSaturdayShoot(false);
    }
    fetchAllPhotoGraphersUnAvailabilityForADay(convertISODateString(date._d));
    setDate(date._d);
    if (values[SCHD_DTLS].events.start) {
      const start = convertISOStrToDate(values[SCHD_DTLS].events.start);
      start.setFullYear(
        date._d.getFullYear(),
        date._d.getMonth(),
        date._d.getDate()
      );
      setFieldValue(`${SCHD_DTLS}.events.start`, convertISODateString(start));
    }
    if (values[SCHD_DTLS].events.end) {
      const end = convertISOStrToDate(values[SCHD_DTLS].events.end);
      end.setFullYear(
        date._d.getFullYear(),
        date._d.getMonth(),
        date._d.getDate()
      );
      setFieldValue(`${SCHD_DTLS}.events.end`, convertISODateString(end));
    }
    if (!selectedPhtgr) {
      setSelectedPhtgr(selectedShoot.events.photographer);
    }
    // TODO: Check with Mohammed why we are defaulting to current start and end
    // setFieldValue(
    // `${SCHD_DTLS}.events.start`,
    // convertISODateString(start ? start : date._d)
    // );
    // setFieldValue(
    // `${SCHD_DTLS}.events.end`,
    // convertISODateString(end ? end : date._d)
    // );
  };

  const notifyMail = async (shoot, text) => {
    notifyClientEdit(shoot, text);
  };
  //OnChange Start Time
  const onChangeTime = (selectedTime, type, setFieldValue) => {
    let endDate;
    if (type === "start") {
      const startDate = new Date(
        date.toDateString() + " " + selectedTime._d.toTimeString()
      );
      const dt = new Date(startDate);
      endDate = new Date(
        dt.setMinutes(dt.getMinutes() + selectedShoot.events.duration)
      );
      setFieldValue(
        `${SCHD_DTLS}.events.start`,
        convertISODateString(startDate)
      );
    } else {
      if (time.start <= selectedTime._d) {
        endDate = selectedTime._d;
      } else {
        message.error("End time should be after the start time");
        const dt = new Date(time.start);
        const shootDuration =
          selectedShoot.events.duration > 0
            ? selectedShoot.events.duration
            : 25;
        endDate = new Date(dt.setMinutes(dt.getMinutes() + shootDuration));
      }
    }
    setFieldValue(`${SCHD_DTLS}.events.end`, convertISODateString(endDate));
    setTime({
      start: type === "start" ? selectedTime._d : start,
      end: endDate,
    });
    if (!selectedPhtgr) {
      setSelectedPhtgr(selectedShoot.events.photographer);
    }
  };

  const disableDate = (current) => {
    return (
      moment(current).day() === 0 ||
      (current && current < moment().startOf("day"))
    );
  };
  const getCoordinates = async (startTime, endTime) => {
    try {
      var geocode;

      let photographerAddres = selectedPhtgr
        ? selectedPhtgr.home_address
        : selectedShoot?.events?.photographer?.home_address;
      photographerAddres = encodeURIComponent(photographerAddres);
      let shootAddress = selectedShoot.address.full;
      shootAddress = encodeURIComponent(shootAddress);
      const getURL = `${process.env.REACT_APP_ROOT_URL}/shoots/getDistance/${photographerAddres}/${shootAddress}/${selectedShoot._id}`;

      const driveTime = await Axios.get(getURL).then((res) => {
        geocode = res.data;
      });
      driveT = geocode.photographer_drive_time;
      updatePhotographerCalc(
        selectedShoot.invoice,
        selectedPhtgr
          ? selectedPhtgr.pay_multiplier
          : currentPhotographer.pay_multiplier,
        selectedShoot.booking_form.sqft,
        selectedShoot.trip_charge,
        startTime,
        endTime,
        driveT,
        { updateInvoice }
      );
      setSelectedPhotographer(selectedPhtgr);
    } catch (e) {
      console.error(e);
    }
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const handleFlex = (value) => {
    setFlex(value);
  };

  const handleSelectedStatus = async (val) => {
    await fetchPaymentsByInvoiceId(selectedInvoice?._id);
    if (val === "Cancelled") {
      setCancelModalVisible(true);
    }

    if (selectedShoot.status === "Cancelled" && selectedInvoice?.canceled) {
      setRestoreModalVisible(true);
      console.log("Are you sure you want to restore this cancelled shoot?");
    }
  };

  const handleCancelConfirmed = async () => {
    if (payments && payments.length > 0) {
      setHasCredit(true);
    }

    if (selectedInvoice?.discount && selectedInvoice?.discount > 0) {
      setHasDiscount(true);
    }

    setSelectedPhtgr(null);
    setCancelModalVisible(false);
  };

  const isSaturday = (date) => {
    return moment(date).isoWeekday() === 6; // 6 represents Saturday
  };

  const renderFormik = (sectionTitle) => {
    return (
      <Formik
        enableReinitialize
        initialValues={formData}
        onSubmit={async (values) => {
          setLoader(true);
          let res;

          if (
            (selectedPhtgr &&
              (currentPhotographer === null ||
                selectedPhtgr._id !== currentPhotographer?._id)) ||
            values[SCHD_DTLS]?.events?.start ||
            values[SCHD_DTLS]?.events?.end
          ) {
            await getCoordinates(
              values[SCHD_DTLS]?.events?.start,
              values[SCHD_DTLS]?.events?.end
            );
            setSelectedPhotographer(selectedPhtgr);
          }

          if (sectionTitle === PRTY_DTLS) {
            const fullAddress = `${values[PRTY_DTLS].address.street}, ${values[PRTY_DTLS].address.city}, ${values[PRTY_DTLS].address.state} ${values[PRTY_DTLS].address.zip}`;
            const body = {
              address: {
                full: fullAddress,
                street: values[PRTY_DTLS].address.street,
                city: values[PRTY_DTLS].address.city,
                apt: values[PRTY_DTLS].address.apt,
                state: values[PRTY_DTLS].address.state,
                zip: values[PRTY_DTLS].address.zip,
                street_lower_no_spaces: values[PRTY_DTLS].address.street
                  .toLowerCase()
                  .replace(/[^A-Za-z0-9\-]/g, ""),
                city_state_zip: `${values[PRTY_DTLS].address.city} ${values[PRTY_DTLS].address.state} ${values[PRTY_DTLS].address.zip}`,
                verify: false,
                drive_time: values[PRTY_DTLS].address.drive_time,
                geometry: {
                  type: "Point",
                  coordinates: [
                    values[PRTY_DTLS].address.geometry.coordinates[0],
                    values[PRTY_DTLS].address.geometry.coordinates[1],
                  ],
                },
              },
              events: {
                ...selectedShoot.events,
                title: `${values[PRTY_DTLS].address.street} - ${
                  selectedShoot.client_name_first
                } ${selectedShoot.client_name_last}${flex ? " - Flex" : ""}`,
              },
              flexible: flex,
            };
            res = await update(selectedShoot._id, body);
            if (!res.error) {
              const invoices = res.data.invoice;

              // Loop through invoices and update invoice.address with new fullAddress
              invoices.forEach(async (invoice) => {
                const invoiceBody = {
                  address: fullAddress,
                };
                await updateInvoice(invoice._id, invoiceBody);
              });
            }
          } else if (sectionTitle === "Schedule Details") {
            if (values[sectionTitle].status === "Cancelled") {
              if (payments && payments.length > 0) {
                if (selectedInvoice?.discount > 0) {
                  // Create a credit to replace used credit
                  await createCredit({
                    valid: true,
                    redeemed: false,
                    client: selectedInvoice?.client?._id,
                    amount: selectedInvoice?.discount,
                    reason: `Reinstated credit previously used on cancelled invoice #${selectedInvoice?.hsn}`,
                    code: "REINSTATED",
                    credit_type: "Refund Credit",
                  });

                  // Create Activity for reinstated credit
                  await createActivity(
                    selectedShoot,
                    `Credit of $${selectedInvoice?.discount?.toFixed(
                      2
                    )} reinstated.`
                  );
                }
                // Create a credit
                await createCredit({
                  valid: true,
                  redeemed: false,
                  client: selectedInvoice?.client?._id,
                  amount: selectedInvoice?.total,
                  reason: `Credit for previously paid balance on Cancelled shoot - HS# ${selectedShoot?.hsn}`,
                  code: "REFUND",
                  credit_type: "Refund Credit",
                });
                // Create Activity
                await createActivity(
                  selectedShoot,
                  `Credit given for paid balance of $${selectedInvoice?.total?.toFixed(
                    2
                  )}.`
                );

                // Update invoice
                await updateInvoice(selectedInvoice?._id, {
                  canceled: true,
                  refunded: true,
                  refund_method: "Credit",
                  refund_amount: selectedInvoice?.total,
                  refund_date: new Date(),
                  balance: 0,
                  paid: false,
                });
              }
              // Update invoice
              await updateInvoice(selectedInvoice?._id, {
                canceled: true,
                balance: 0,
              });
              // Remove photographer
              values[sectionTitle].events.photographer = null;
              // Create Activity
              createActivity(
                selectedShoot,
                `Shoot marked as Cancelled. Reason: ${cancelReason}`
              );
              setHasCredit(false);
              setHasDiscount(false);
            }
            if (
              selectedShoot.status === "Cancelled" &&
              values[sectionTitle].status !== "Cancelled" &&
              selectedInvoice?.canceled
            ) {
              const res = await updateInvoice(selectedInvoice?._id, {
                ...selectedInvoice,
                balance: selectedInvoice.paid
                  ? selectedInvoice.balance
                  : selectedInvoice?.total,
                canceled: false,
              });
              if (!res.error) {
                createActivity(
                  selectedShoot,
                  `Cancelled Shoot marked as restored. Reason: ${restoreReason}`
                );
              }
            }
            res = await update(selectedShoot._id, {
              ...values[sectionTitle],
              flexible: flex,
              events: {
                ...(values[sectionTitle].events
                  ? values[sectionTitle].events
                  : selectedShoot.events),
                title: `${selectedShoot.address.street} - ${
                  selectedShoot.client_name_first
                } ${selectedShoot.client_name_last}${flex ? " - Flex" : ""}`,
              },
            });
          } else if (sectionTitle === "Appointment Details") {
            res = await update(selectedShoot._id, {
              ...values[sectionTitle],
              flexible: flex,
              events: {
                ...selectedShoot.events,
                title: `${selectedShoot.address.street} - ${
                  selectedShoot.client_name_first
                } ${selectedShoot.client_name_last}${flex ? " - Flex" : ""}`,
              },
            });
          } else if (sectionTitle === "Client Details") {
            res = await update(selectedShoot._id, {
              ...values[sectionTitle],
              flexible: flex,
              events: {
                ...selectedShoot.events,
                title: `${selectedShoot.address.street} - ${
                  selectedShoot.client_name_first
                } ${selectedShoot.client_name_last}${flex ? " - Flex" : ""}`,
              },
            });
          } else if (sectionTitle === "Secondary Contact Details") {
            res = await update(selectedShoot._id, {
              ...values[sectionTitle],
              flexible: flex,
              events: {
                ...selectedShoot.events,
                title: `${selectedShoot.address.street} - ${
                  selectedShoot.client_name_first
                } ${selectedShoot.client_name_last}${flex ? " - Flex" : ""}`,
              },
            });
          }
          if (res.status === 200) {
            if (sectionTitle === SCHD_DTLS) {
              let activityInfo,
                activityInfo1,
                activityInfo2,
                activityInfo3,
                activityInfo4;
              if (
                selectedPhtgr &&
                currentPhotographer &&
                currentPhotographer?._id !== selectedPhtgr?._id
              ) {
                activityInfo1 = `Assigned photographer changed from ${
                  currentPhotographer.name || "Unassigned"
                } to ${selectedPhtgr.name}.`;
              }

              if (
                values[SCHD_DTLS]?.events?.start &&
                (selectedShoot?.events?.start !==
                  values[SCHD_DTLS]?.events?.start ||
                  selectedShoot?.events?.end !== values[SCHD_DTLS]?.events?.end)
              ) {
                activityInfo2 = `Scheduled time updated to ${eventDateFormat(
                  values[SCHD_DTLS]?.events?.start,
                  values[SCHD_DTLS]?.events?.end
                )}.`;
              }
              if (
                values[SCHD_DTLS]?.status &&
                selectedShoot?.status !== values[SCHD_DTLS]?.status
              ) {
                activityInfo3 = `Appointment status updated from ${selectedShoot?.status} to ${values[SCHD_DTLS]?.status}.`;
              }

              if (
                values[SCHD_DTLS]?.flexible &&
                selectedShoot?.flexible !== values[SCHD_DTLS]?.flexible
              ) {
                activityInfo4 = `Appointment was changed to ${
                  values[SCHD_DTLS]?.flexible ? "Flex." : "non-Flex."
                }`;
              }

              if (
                activityInfo ||
                activityInfo1 ||
                activityInfo2 ||
                activityInfo3 ||
                activityInfo4
              ) {
                createActivity(
                  selectedShoot,
                  `${activityInfo || ""} ${activityInfo1 || ""} ${
                    activityInfo2 || ""
                  } ${activityInfo3 || ""} ${activityInfo4 || ""}`
                );
              }
            } else if (sectionTitle === APPT_DTLS) {
              let activityInfo,
                activityInfo1,
                activityInfo2,
                activityInfo3,
                activityInfo4,
                activityInfo5,
                activityInfo6,
                activityInfo7,
                activityInfo8;
              if (
                res.data.booking_form.entry_id !==
                  selectedShoot.booking_form.entry_id &&
                selectedShoot.booking_form.entry_id !== ""
              ) {
                activityInfo1 = `Entry Id changed from ${
                  selectedShoot.booking_form.entry_id === ""
                    ? "none"
                    : selectedShoot.booking_form.entry_id
                } to ${res.data.booking_form.entry_id}.`;
              }
              if (
                res.data.booking_form.entry_method !==
                selectedShoot.booking_form.entry_method
              ) {
                activityInfo2 = `Entry Method changed from ${selectedShoot.booking_form.entry_method} to ${res.data.booking_form.entry_method}.`;
              }
              if (
                res.data.booking_form.appt_type !==
                selectedShoot.booking_form.appt_type
              ) {
                activityInfo3 = `Appointment type changed from ${selectedShoot.booking_form.appt_type} to ${res.data.booking_form.appt_type}.`;
              }
              if (
                res.data.booking_form.shoot_type !==
                selectedShoot.booking_form.shoot_type
              ) {
                activityInfo8 = `Shoot type changed from ${selectedShoot.booking_form.shoot_type} to ${res.data.booking_form.shoot_type}.`;
              }
              if (
                res.data.booking_form.drone_focus !==
                selectedShoot.booking_form.drone_focus
              ) {
                activityInfo4 = `Drone focus changed from ${
                  selectedShoot.booking_form.drone_focus === ""
                    ? "none"
                    : selectedShoot.booking_form.drone_focus
                } to ${res.data.booking_form.drone_focus}.`;
              }
              if (
                res.data.booking_form.resi !== selectedShoot.booking_form.resi
              ) {
                activityInfo5 = `Occupancy changed from ${selectedShoot.booking_form.resi} to ${res.data.booking_form.resi}.`;
              }
              if (
                res.data.booking_form.list_price !==
                selectedShoot.booking_form.list_price
              ) {
                activityInfo6 = `List Price changed from ${selectedShoot.booking_form.list_price} to ${res.data.booking_form.list_price}.`;
              }
              if (
                res.data.booking_form.sqft !== selectedShoot.booking_form.sqft
              ) {
                activityInfo7 = `Sqft changed from ${selectedShoot.booking_form.sqft} to ${res.data.booking_form.sqft}.`;
              }
              if (
                activityInfo ||
                activityInfo1 ||
                activityInfo2 ||
                activityInfo3 ||
                activityInfo4 ||
                activityInfo5 ||
                activityInfo6 ||
                activityInfo7 ||
                activityInfo8
              ) {
                createActivity(
                  selectedShoot,
                  `${activityInfo || ""} ${activityInfo1 || ""} ${
                    activityInfo2 || ""
                  } ${activityInfo3 || ""} ${activityInfo4 || ""} ${
                    activityInfo5 || ""
                  } ${activityInfo6 || ""} ${activityInfo7 || ""} ${
                    activityInfo8 || ""
                  }`
                );
                notifyMail(
                  selectedShoot,
                  `${activityInfo || ""} ${activityInfo1 || ""} ${
                    activityInfo2 || ""
                  } ${activityInfo3 || ""} ${activityInfo4 || ""} ${
                    activityInfo5 || ""
                  } ${activityInfo6 || ""} ${activityInfo7 || ""} ${
                    activityInfo8 || ""
                  }`
                );
              }
            } else if (sectionTitle === SC_DTLS) {
              createActivity(selectedShoot, `${SC_DTLS} were changed.`);
            } else if (sectionTitle === CLNT_DTLS) {
              createActivity(selectedShoot, `${CLNT_DTLS} were changed.`);
            } else if (sectionTitle === PRTY_DTLS) {
              let activityInfo,
                activityInfo1,
                activityInfo2,
                activityInfo3,
                activityInfo4,
                activityInfo5,
                activityInfo6,
                activityInfo7;
              if (res.data.address.street !== selectedShoot.address.street) {
                activityInfo1 = `Street changed from ${selectedShoot.address.street} to ${res.data.address.street}.`;
              }
              if (res.data.address.apt !== selectedShoot.address.apt) {
                activityInfo2 = `Unit/Apt changed from ${
                  selectedShoot.address.apt === ""
                    ? "none"
                    : selectedShoot.address.apt
                } to ${res.data.address.apt}.`;
              }

              if (res.data.address.city !== selectedShoot.address.city) {
                activityInfo3 = `City changed from ${selectedShoot.address.city} to ${res.data.address.city}.`;
              }
              if (res.data.address.state !== selectedShoot.address.state) {
                activityInfo4 = `State changed from ${selectedShoot.address.state} to ${res.data.address.state}.`;
              }
              if (
                res.data.address.zip_code !== selectedShoot.address.zip_code
              ) {
                activityInfo5 = `zipcode changed from ${selectedShoot.address.zip_code} to ${res.data.address.zip_code}.`;
              }
              if (
                res.data.address.geometry.coordinates[1] !==
                selectedShoot.address.geometry.coordinates[1]
              ) {
                activityInfo6 = `Latitude changed from ${selectedShoot.address.geometry.coordinates[1]} to ${res.data.address.geometry.coordinates[1]}.`;
              }
              if (
                res.data.address.geometry.coordinates[0] !==
                selectedShoot.address.geometry.coordinates[0]
              ) {
                activityInfo7 = `Longitude changed from ${selectedShoot.address.geometry.coordinates[0]} to ${res.data.address.geometry.coordinates[0]}.`;
              }
              if (
                activityInfo ||
                activityInfo1 ||
                activityInfo2 ||
                activityInfo3 ||
                activityInfo4 ||
                activityInfo5 ||
                activityInfo6 ||
                activityInfo7
              ) {
                createActivity(
                  selectedShoot,
                  `${activityInfo || ""} ${activityInfo1 || ""} ${
                    activityInfo2 || ""
                  } ${activityInfo3 || ""} ${activityInfo4 || ""} ${
                    activityInfo5 || ""
                  } ${activityInfo6 || ""} ${activityInfo7 || ""}`
                );
                notifyMail(
                  selectedShoot,
                  `${activityInfo || ""} ${activityInfo1 || ""} ${
                    activityInfo2 || ""
                  } ${activityInfo3 || ""} ${activityInfo4 || ""} ${
                    activityInfo5 || ""
                  } ${activityInfo6 || ""} ${activityInfo7 || ""}`
                );
              }
            }
            message.success({
              content: "Shoot updated successfully!",
              style: {
                marginTop: "10vh",
              },
            });
          } else {
            message.error("Oops! Error occured while updating shoot");
          }
          setFormData(values);
          setModal({ ...modal, editModal: false });
          setLoader(false);
        }}
        render={({ handleSubmit, setFieldValue, values }) => (
          <div className="form-container">
            <Spin spinning={loader}>
              <Form>
                {sectionTitle === SCHD_DTLS && (
                  <>
                    <h4>
                      <ClockCircleOutlined /> {sectionTitle}
                    </h4>
                    {hasCredit && (
                      <Alert
                        message={`A credit of $${selectedInvoice?.total?.toFixed(
                          2
                        )} will
                      be applied to refund the paid invoice for the Cancelled shoot.`}
                        type="success"
                      />
                    )}
                    {hasDiscount && (
                      <Alert
                        message={`A credit of $${selectedInvoice?.discount?.toFixed(
                          2
                        )} will
                      be applied to reinstate the credit redeemed on the Cancelled invoice.`}
                        type="success"
                        style={{ marginTop: 5 }}
                      />
                    )}
                    {isSaturdayShoot && (
                      <Alert
                        message={`This will be a Saturday shoot. Please make sure to add the Saturday rate to the invoice.`}
                        type="warning"
                        style={{ marginTop: 5 }}
                      />
                    )}
                    <Row gutter={16}>
                      <Col span={24}>
                        <div className="form-group">
                          <label>Date</label>
                          <DatePicker
                            allowClear={false}
                            className="tui-full-calendar-content"
                            format="YYYY-MM-DD"
                            onChange={(date) =>
                              onChangeDate(date, values, setFieldValue)
                            }
                            placeholder="Date"
                            style={{
                              outline: "none",
                              marginRight: "5px",
                            }}
                            defaultValue={moment(date, "YYYY-MM-DD")}
                            disabledDate={disableDate}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="form-group">
                          <label>From</label>
                          <TimePicker
                            allowClear={false}
                            className="tui-full-calendar-content"
                            format="h:mm A"
                            onChange={(d) =>
                              onChangeTime(d, "start", setFieldValue)
                            }
                            placeholder="Start Time"
                            use12Hours
                            value={moment(start, "h:mm A")}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="form-group">
                          <label>To</label>
                          <TimePicker
                            allowClear={false}
                            className="tui-full-calendar-content"
                            format="h:mm A"
                            onChange={(d) =>
                              onChangeTime(d, "end", setFieldValue)
                            }
                            placeholder="End Time"
                            use12Hours
                            value={moment(end, "h:mm A")}
                          />
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="form-group">
                          <label>Photographer</label>
                          <AssignPhotoGrapher
                            unavailablePhotographers={unavailablePhotographers}
                            status={selectedShoot.status}
                            assigned={
                              currentPhotographer
                                ? currentPhotographer?._id
                                : selectedShoot.events?.photographer?._id
                            }
                            reset={reset}
                            setReset={setReset}
                            photographers={photographers}
                            setPhotographer={(val) => {
                              setFieldValue(
                                `${SCHD_DTLS}.events.photographer`,
                                val
                              );
                              setSelectedPhtgr(
                                val
                                  ? photographers.filter(
                                      (pg) => pg._id == val
                                    )[0]
                                  : ""
                              );
                            }}
                            getSelectedPhotographer={
                              selectedPhotographers
                                ? selectedPhotographers
                                : selectedShoot.events.photographer
                            }
                            destroyOnClose={true}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="form-group">
                          <label>Status</label>
                          <Select
                            defaultValue={capitalize(selectedShoot.status)}
                            style={{ width: 120 }}
                            onChange={(value) =>
                              setFieldValue(`${SCHD_DTLS}.status`, value)
                            }
                            onSelect={handleSelectedStatus}
                          >
                            <Option value="Pending">Pending</Option>
                            <Option value="Tentative">Tentative</Option>
                            <Option value="Scheduled">Scheduled</Option>
                            <Option value="Postpone">Postpone</Option>
                            <Option value="Reschedule">Reschedule</Option>
                            <Option value="Cancelled">Cancelled</Option>
                            <Option value="Processing">Processing</Option>
                            <Option value="Completed">Completed</Option>
                            <Option value="Training">Training</Option>
                          </Select>
                        </div>
                      </Col>
                      <Col span={12} className="mt-3">
                        <div className="mb-4">
                          <span className="mr-2">
                            <strong>Flex</strong>
                          </span>
                          <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={flex}
                            onChange={(value) =>
                              setFieldValue(`${SCHD_DTLS}.flexible`, value)
                            }
                            onClick={handleFlex}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                {sectionTitle === APPT_DTLS && (
                  <div className="mt-2">
                    <h4>
                      <CalendarOutlined /> {sectionTitle}
                    </h4>
                    <Row gutter={16}>
                      <Col span={24}>
                        <div className="form-group">
                          <label>Type of Shoot</label>
                          <Select
                            defaultValue={capitalize(
                              selectedShoot?.booking_form?.shoot_type
                            )}
                            style={{ width: 200 }}
                            onChange={(value) =>
                              setFieldValue(
                                `${APPT_DTLS}.booking_form.shoot_type`,
                                value
                              )
                            }
                          >
                            <Option value="Residential">Residential</Option>
                            <Option value="Multi-family">Multi-family</Option>
                            <Option value="Commercial">Commercial</Option>
                            <Option value="Additional">Additional Shots</Option>
                          </Select>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="form-group">
                          <label>Entry Method</label>
                          <Input
                            name={`${APPT_DTLS}.booking_form.entry_method`}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="form-group">
                          <label>Entry Code</label>
                          <Input name={`${APPT_DTLS}.booking_form.entry_id`} />
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="form-group">
                          <label>Occupancy</label>
                          <Input name={`${APPT_DTLS}.booking_form.resi`} />
                        </div>
                        <div className="form-group">
                          <label>Appointment Type</label>
                          <Input name={`${APPT_DTLS}.booking_form.appt_type`} />
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="form-group">
                          <label>Drone Focus</label>
                          <Input
                            name={`${APPT_DTLS}.booking_form.drone_focus`}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="form-group">
                          <label>Sqft.</label>
                          <Input
                            type="number"
                            name={`${APPT_DTLS}.booking_form.sqft`}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="form-group">
                          <label>List Price</label>
                          <Input
                            type="number"
                            name={`${APPT_DTLS}.booking_form.list_price`}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                {sectionTitle === PRTY_DTLS && (
                  <>
                    <h4>
                      <EnvironmentOutlined /> {sectionTitle}
                    </h4>
                    <Row gutter={16}>
                      <Col span={16}>
                        <div className="form-group">
                          <label>Street</label>
                          <Input
                            type="text"
                            name={`${PRTY_DTLS}.address.street`}
                          />
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="form-group">
                          <label>Unit/Apt.</label>
                          <Input
                            type="text"
                            name={`${PRTY_DTLS}.address.apt`}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="form-group">
                          <label>City</label>
                          <Input
                            type="text"
                            name={`${PRTY_DTLS}.address.city`}
                          />
                        </div>
                      </Col>
                      <Col span={6}>
                        <div className="form-group">
                          <label>State</label>
                          <Input
                            type="text"
                            name={`${PRTY_DTLS}.address.state`}
                          />
                        </div>
                      </Col>
                      <Col span={6}>
                        <div className="form-group">
                          <label>Zip Code</label>
                          <Input
                            type="text"
                            name={`${PRTY_DTLS}.address.zip`}
                          />
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="form-group">
                          <label>Latitude</label>
                          <Input
                            type="text"
                            name={`${PRTY_DTLS}.address.geometry.coordinates[1]`}
                          />
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="form-group">
                          <label>Longitude</label>
                          <Input
                            type="text"
                            name={`${PRTY_DTLS}.address.geometry.coordinates[0]`}
                          />
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="form-group">
                          <label>Drive Time (mins)</label>
                          <Input
                            type="text"
                            name={`${PRTY_DTLS}.address.drive_time`}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                {sectionTitle === CLNT_DTLS && (
                  <>
                    <h4 style={{ marginTop: "10px" }}>
                      <HomeOutlined /> {sectionTitle}
                    </h4>
                    <Row gutter={16}>
                      <Col span={12}>
                        <div className="form-group">
                          <label>First Name</label>
                          <Input name={`${CLNT_DTLS}.client_name_first`} />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="form-group">
                          <label>Last Name</label>
                          <Input name={`${CLNT_DTLS}.client_name_last`} />
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="form-group">
                          <label>Email</label>
                          <Input name={`${CLNT_DTLS}.client_email`} />
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="form-group">
                          <label>Phone</label>
                          <Input name={`${CLNT_DTLS}.client_phone`} />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                {sectionTitle === SC_DTLS && (
                  <>
                    <h4 style={{ marginTop: "10px" }}>
                      <UserSwitchOutlined /> {sectionTitle}
                    </h4>
                    <Row gutter={16}>
                      <Col span={12}>
                        <div className="form-group">
                          <label>Name</label>
                          <Input
                            name={`${SC_DTLS}.booking_form.secondary_contact.name`}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="form-group">
                          <label>E mail</label>
                          <Input
                            name={`${SC_DTLS}.booking_form.secondary_contact.email`}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="form-group">
                          <label>Phone</label>
                          <Input
                            name={`${SC_DTLS}.booking_form.secondary_contact.phone`}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="form-group">
                          <label>What is the role of this contact?</label>
                          <Input
                            placeholder="e.g. Assistant, Homeowner, Apt. Manager"
                            name={`${SC_DTLS}.booking_form.secondary_contact.role`}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col span={24}>
                    <Button
                      onClick={handleSubmit}
                      type="primary"
                      style={{ marginTop: "10px" }}
                    >
                      Save
                    </Button>
                    <Button
                      type="link"
                      onClick={() => handleCancel("editModal")}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
                <Modal
                  visible={cancelModalVisible}
                  onOk={handleCancelConfirmed}
                  okText="Yes"
                  onCancel={() => setCancelModalVisible(false)}
                  maskClosable={false}
                >
                  <div className="form-group mt-3 mb-3">
                    <p>Are you sure you want to Cancel this shoot?</p>
                    <div className="form-group mb-4">
                      <label>Reason:</label>
                      <Input
                        onChange={(e) => setCancelReason(e.target.value)}
                        placeholder="e.g. Client cancelled to reschedule later."
                        name="cancel_reason"
                      />
                    </div>
                  </div>
                </Modal>
                <Modal
                  visible={restoreModalVisible}
                  onOk={() => setRestoreModalVisible(false)}
                  okText="Yes"
                  onCancel={() => setRestoreModalVisible(false)}
                  maskClosable={false}
                >
                  <div className="form-group mt-3 mb-3">
                    <p>
                      Are you sure you want to restore this cancelled shoot?
                    </p>
                    <div className="form-group mb-4">
                      <label>Reason:</label>
                      <Input
                        onChange={(e) => setRestoreReason(e.target.value)}
                        placeholder="e.g. Client decided to uncancel."
                        name="restore_reason"
                      />
                    </div>
                  </div>
                </Modal>
              </Form>
            </Spin>
          </div>
        )}
      />
    );
  };

  const editSection = (section) => {
    setDate(
      selectedShoot.events.start
        ? convertISOStrToDate(selectedShoot.events?.start)
        : new Date()
    );
    setTime({
      start: selectedShoot.events.start
        ? convertISOStrToDate(selectedShoot.events?.start)
        : new Date(),
      end: selectedShoot.events.end
        ? convertISOStrToDate(selectedShoot.events?.end)
        : convertISOStrToDate(
            convertISODateString(
              new Date(
                new Date().setMinutes(
                  new Date().getMinutes() + selectedShoot.events?.duration
                )
              )
            )
          ),
    });
    setModal({ ...modal, editModal: true });
    setSectionTitle(section);
  };

  const renderEditButton = (sectionTitle) => {
    return (
      <Button
        className="float-right"
        onClick={() => editSection(sectionTitle)}
        type="link"
      >
        <EditOutlined /> Edit
      </Button>
    );
  };
  const renderEditContact = (sectionTitle) => {
    return (
      <Button
        onClick={() => editSection(sectionTitle)}
        type="link"
        style={{
          paddingTop: "10px",
          marginLeft: "30px",
        }}
      >
        + Add Secondary Contact
      </Button>
    );
  };

  const renderFlexDateTime = (shoot) => {
    return (
      <span className="mr-2">
        {shoot?.events?.start &&
          !shoot?.flexible &&
          eventDateFormat(shoot?.events?.start, shoot?.events?.end)}
        {!shoot?.events?.start && "---"}
        {shoot?.events?.start &&
          shoot?.flexible &&
          moment.utc(shoot?.events?.start).format("MM/DD/YY")}
      </span>
    );
  };

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col xs={24} sm={24} md={10} lg={10} className="mb-4">
        {selectedShoot && (
          <div>
            <div className="mb-2">
              {selectedShoot.original_shoot && (
                <Alert
                  message={`Reschedule of ${selectedShoot.original_shoot?.hsn}`}
                  type="info"
                  action={
                    <a
                      href={`/admin/shoots/${selectedShoot.original_shoot?._id}`}
                      style={{ color: "#1890ff" }}
                    >
                      View Original Shoot
                    </a>
                  }
                  banner
                />
              )}
              {selectedShoot.rescheduled_shoot && (
                <Alert
                  message={`Rescheduled to ${selectedShoot.rescheduled_shoot?.hsn}`}
                  type="warning"
                  action={
                    <a
                      href={`/admin/shoots/${selectedShoot.rescheduled_shoot?._id}`}
                      style={{ color: "#1890ff" }}
                    >
                      View Rescheduled Shoot
                    </a>
                  }
                  banner
                />
              )}
            </div>
            {ability.can("request", "Confirmation") && (
              <div className="float-right">
                <SendEmail
                  shoot={selectedShoot}
                  firstName={selectedShoot?.client_name_first}
                  lastName={selectedShoot?.client_name_last}
                  appointmentDate={`${moment
                    .utc(selectedShoot?.events.start)
                    .format("dddd")}, the ${moment
                    .utc(selectedShoot?.events.start)
                    .format("Do")}`}
                  appointmentTime={`${moment
                    .utc(selectedShoot?.events.start)
                    .format("dddd")}, the ${moment
                    .utc(selectedShoot?.events.start)
                    .format("Do")} at ${moment
                    .utc(selectedShoot?.events.start)
                    .format("h:mma")
                    .replace("m", "")}`}
                  appointmentAddress={selectedShoot?.address?.street.replace(
                    "null",
                    ""
                  )}
                  cutOffTime={`${moment
                    .utc(selectedShoot?.events.start)
                    .subtract(3, "hours")
                    .format("h:mma")
                    .replace("m", "")}`}
                  primaryEmail={selectedShoot?.client_email}
                  secondaryEmail={
                    selectedShoot?.booking_form?.secondary_contact?.email ||
                    null
                  }
                />
              </div>
            )}
            <Paragraph
              style={{
                fontSize: "16px",
                color: "#333",
                marginRight: 16,
              }}
              copyable={{ text: `HS# ${selectedShoot.hsn}` }}
            >
              HS# {selectedShoot.hsn}
            </Paragraph>

            {renderSection(
              <CalendarOutlined />,
              <div style={{ marginBottom: "10px" }}>
                {ability.can("manage", "Shoots")
                  ? eventDateFormat(
                      selectedShoot.events.start
                        ? selectedShoot.events.start
                        : convertISODateString(new Date()),
                      selectedShoot.events.end
                        ? selectedShoot.events.end
                        : convertISODateString(
                            new Date(
                              new Date().setMinutes(
                                new Date().getMinutes() +
                                  selectedShoot.events.duration
                              )
                            )
                          )
                    )
                  : renderFlexDateTime(selectedShoot)}{" "}
                <Tag
                  color={statusTagColor(selectedShoot.status || selectedShoot)}
                >
                  {selectedShoot.status}
                </Tag>
                {selectedShoot.flexible && <Tag color="magenta">Flex</Tag>}
                {(selectedShoot.reqs?.includes("DLX") ||
                  selectedShoot.reqs?.includes("ADLX")) && (
                  <Tag color="purple">
                    {" "}
                    <CrownOutlined /> Deluxe
                  </Tag>
                )}
                {selectedShoot.reqs?.includes("T") && (
                  <Tooltip placement="topRight" title="Twilight Timeslot">
                    <img src={TwilightIcon} alt="Twilight" />
                  </Tooltip>
                )}
                <Can do="manage" on="Shoots">
                  {renderEditButton(SCHD_DTLS)}
                </Can>
                {((currentPhotographer && currentPhotographer?.name) ||
                  (selectedShoot.events.photographer &&
                    selectedShoot.events.photographer.name)) && (
                  <>
                    <br />
                    Assigned to{" "}
                    <strong>
                      {currentPhotographer?.name ||
                        selectedShoot.events?.photographer?.name}
                    </strong>{" "}
                    {currentPhotographer &&
                    currentPhotographer?.headshot_url ? (
                      <Avatar
                        src={currentPhotographer?.headshot_url}
                        className="mr-1"
                        size={35}
                      />
                    ) : null}
                  </>
                )}
                <div>
                  {(ability.can("manage", "Shoots") ||
                    (auth?.user?.access.includes("CLIENT") &&
                      selectedShoot.status === "Pending")) &&
                    renderEditButton(APPT_DTLS)}
                </div>
                <span>
                  <strong>Requested Date:</strong>{" "}
                  {selectedShoot.booking_form.requested_date
                    ? moment(selectedShoot.booking_form.requested_date)
                        .utc()
                        .format("MM/DD/YY")
                    : "ASAP"}{" "}
                  {selectedShoot.booking_form.alternative_date && (
                    <span className="ml-3">
                      <strong>Alternative Date:</strong>{" "}
                      {moment(selectedShoot.booking_form.alternative_date)
                        .utc()
                        .format("MM/DD/YY")}
                    </span>
                  )}
                  <br />
                </span>
                <div>
                  <strong>Will the agent/owner be onsite?:</strong>{" "}
                  {selectedShoot.booking_form.agent_onsite ? "Yes" : "No"}
                </div>
                {renderLabel("Occupancy", selectedShoot.booking_form.resi)}
                {renderLabel(
                  "Appointment Type",
                  selectedShoot.booking_form.appt_type
                )}
                {renderLabel(
                  "Entry Method",
                  selectedShoot.booking_form.entry_method
                )}
                {renderLabel("Entry Code", selectedShoot.booking_form.entry_id)}
                {renderLabel(
                  "Drone Focus",
                  selectedShoot.booking_form.drone_focus
                )}
                {renderLabel(
                  "List Price",
                  <NumberFormat
                    value={selectedShoot.booking_form.list_price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                )}
                {renderLabel(
                  "Sqft.",
                  <NumberFormat
                    value={selectedShoot.booking_form.sqft}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                )}{" "}
              </div>
            )}
            {renderSection(
              <HomeOutlined />,
              <div>
                <div>
                  {(ability.can("manage", "Shoots") ||
                    (auth?.user?.access.includes("CLIENT") &&
                      selectedShoot.status === "Pending")) &&
                    renderEditButton(PRTY_DTLS)}
                </div>
                <div>
                  <Paragraph
                    style={{
                      fontSize: "16px",
                      color: "#333",
                      marginRight: 16,
                    }}
                    copyable={{
                      text: `${formatUsAddress(
                        selectedShoot.address.full,
                        selectedShoot.address.street,
                        selectedShoot.address.apt,
                        selectedShoot.address.city,
                        selectedShoot.address.state,
                        selectedShoot.address.zip
                      )}`,
                    }}
                  >
                    {formatUsAddress(
                      selectedShoot.address.full,
                      selectedShoot.address.street,
                      selectedShoot.address.apt,
                      selectedShoot.address.city,
                      selectedShoot.address.state,
                      selectedShoot.address.zip
                    )}
                  </Paragraph>
                  <div>
                    <Popover
                      placement="right"
                      content={
                        <PreviewMap
                          isMarkerShown
                          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
                          loadingElement={
                            <div
                              style={{
                                height: "100%",
                              }}
                            />
                          }
                          containerElement={
                            <div
                              style={{
                                height: "300px",
                                width: "400px",
                              }}
                            />
                          }
                          mapElement={
                            <div
                              style={{
                                height: "300px",
                              }}
                            />
                          }
                          centerLat={
                            selectedShoot?.address?.geometry?.coordinates[1]
                          }
                          centerLng={
                            selectedShoot?.address?.geometry?.coordinates[0]
                          }
                        />
                      }
                      title={selectedShoot.address.full}
                      trigger="click"
                    >
                      <Button size="small" className="ml-2 mr-2">
                        <EnvironmentOutlined /> See Map
                      </Button>
                    </Popover>
                    {ability.can("read", "Directions") && (
                      <Button
                        size="small"
                        className="mr-2"
                        onClick={() =>
                          window.open(directionsUrl(selectedShoot), "_blank")
                        }
                      >
                        <CarOutlined /> Directions
                      </Button>
                    )}
                    {ability.can("read", "Directions") && (
                      <TripChargeCalc
                        shoot={selectedShoot}
                        invoice={selectedInvoice}
                        updateInvoice={updateInvoice}
                        createActivity={createActivity}
                        updateShoot={update}
                        hasAccess={user?.access.includes("UBERADMIN")}
                        manualFetch={() => {
                          setManualFetch(true);
                        }}
                      />
                    )}
                  </div>
                </div>
                {verifyAddress(selectedShoot) &&
                  ability.can("confirm", "Address") && (
                    <Alert
                      message="Confirm or Edit Address Above"
                      description={
                        <div>{`Client entered: ${
                          selectedShoot?.booking_form?.street || ""
                        }
${
  selectedShoot?.booking_form?.apt
    ? ` #${selectedShoot?.booking_form?.apt}`
    : ""
}, ${selectedShoot?.booking_form?.city || ""}
${selectedShoot?.booking_form?.state || ""}
${selectedShoot?.booking_form?.zip_code || ""}`}</div>
                      }
                      type="warning"
                      showIcon
                      action={
                        <Button onClick={handleAddressConfirm} size="small">
                          Confirm
                        </Button>
                      }
                    />
                  )}
              </div>
            )}
            {renderSection(
              <UserOutlined />,
              <div>
                <div>
                  <Can do="edit" on="Shoots">
                    {renderEditButton(CLNT_DTLS)}
                  </Can>
                </div>
                <p>
                  {user?.access.includes("ADMIN") ||
                  user?.access.includes("COMMS") ? (
                    <Link
                      to={`/admin/clients/${selectedShoot?.client_id}`}
                      style={{ color: "#1890ff" }}
                    >{`${selectedShoot?.client_name_first} ${selectedShoot?.client_name_last}`}</Link>
                  ) : (
                    `${
                      selectedShoot?.client_name_first ||
                      selectedShoot.details?.client_name_first
                    } ${
                      selectedShoot?.client_name_last ||
                      selectedShoot.details?.client_name_last
                    }`
                  )}{" "}
                  -{" "}
                  {selectedShoot?.client_email ||
                    selectedShoot?.details?.client_email}
                  <CopyToClipboard
                    text={selectedShoot?.client_email}
                    onCopy={() => message.info("Email copied to clipboard")}
                  >
                    <Tooltip title="Copy Email">
                      <Button type="link" style={{ padding: 5 }}>
                        <CopyOutlined />
                      </Button>
                    </Tooltip>
                  </CopyToClipboard>
                  <br />
                  {selectedShoot?.client_phone ||
                    selectedShoot?.details?.client_phone}
                </p>
                {selectedShoot.booking_form?.hear_by === "Referral" ||
                selectedShoot.booking_form?.refer_fn !== ""
                  ? renderLabel(
                      "Referred By",
                      `${selectedShoot.booking_form.refer_fn} ${
                        selectedShoot.booking_form.refer_ln || ""
                      }`
                    )
                  : null}
              </div>
            )}
            {(selectedShoot?.booking_form?.secondary_contact?.name ||
              selectedShoot?.booking_form?.secondary_contact?.email ||
              selectedShoot?.booking_form?.secondary_contact?.phone ||
              selectedShoot?.booking_form?.secondary_contact?.role) &&
              renderSection(
                <UserSwitchOutlined />,
                <div>
                  <div>
                    <Can do="edit" on="Shoots">
                      {renderEditButton(SC_DTLS)}
                    </Can>
                  </div>
                  <p>
                    {`${selectedShoot?.booking_form?.secondary_contact?.name} ${
                      selectedShoot?.booking_form?.secondary_contact?.role
                        ? `(${selectedShoot?.booking_form?.secondary_contact?.role}) `
                        : ""
                    } - ${
                      selectedShoot?.booking_form?.secondary_contact?.email
                    }`}
                    <br />
                    {selectedShoot?.booking_form?.secondary_contact?.phone}
                  </p>
                </div>
              )}
            {(user?.access?.includes("ADMIN") ||
              user?.access?.includes("CLIENT") ||
              user?.access?.includes("COMMS")) &&
              selectedShoot?.booking_form?.secondary_contact?.email === "" &&
              selectedShoot?.booking_form?.secondary_contact?.role === "" &&
              selectedShoot?.booking_form?.secondary_contact?.name === "" &&
              selectedShoot?.booking_form?.secondary_contact?.phone === "" && (
                <Can do="edit" on="Shoots">
                  {renderEditContact(SC_DTLS)}
                </Can>
              )}
            {ability.can("read", "Invoices") &&
              renderSection(
                <FileDoneOutlined />,
                <InvoiceList
                  auth={auth}
                  manualFetch={() => {
                    setManualFetch(true);
                  }}
                  openModal={() => setModal({ ...modal, invModal: true })}
                  invoices={selectedShoot.invoice}
                  selectedShoot={selectedShoot}
                  invoice={selectedInvoice}
                  offerings={offerings}
                  updateInvoice={updateInvoice}
                  createCredit={createCredit}
                  updateShoot={update}
                  createActivity={createActivity}
                  photographer={
                    selectedPhtgr ? selectedPhtgr : currentPhotographer
                  }
                  fetchInvoiceById={fetchInvoiceById}
                />
              )}
            {ability.can("create", "Invoices") && (
              <div className="mt-4 ml-5">
                <CreateInvoiceButton shoot={selectedShoot} />
              </div>
            )}
          </div>
        )}
        <Modal
          destroyOnClose={true}
          visible={editModal}
          onOk={() => handleOk("editModal")}
          onCancel={() => handleCancel("editModal")}
          maskClosable={false}
          footer={null}
        >
          {renderFormik(sectionTitle)}
        </Modal>
        <Modal
          destroyOnClose={true}
          visible={invModal}
          onOk={() => handleOk("invModal")}
          onCancel={() => handleCancel("invModal")}
          footer={null}
          width={700}
          style={{ top: 20 }}
        >
          <Invoice
            id={selectedShoot._id}
            manualFetch={() => {
              setManualFetch(true);
            }}
          />
        </Modal>
      </Col>
      <Col xs={24} sm={24} md={14} lg={14}>
        <Row>
          <Can do="read" on="Notes">
            <Col xs={24} sm={24} md={12} lg={12}>
              <h3>Notes</h3>
              <Notes entityType="Shoot" data={selectedShoot} />
            </Col>
          </Can>
          <Can do="read" on="Activity">
            <Col xs={24} sm={24} md={12} lg={12}>
              <h3>Activity</h3>
              <div className="timeline-container">
                <Timeline>
                  {activities.length ? (
                    activities
                      .sort((a, b) => moment(b._created_at).diff(a._created_at))
                      //activities
                      .map((acty) => (
                        <Timeline.Item
                          color={
                            acty.entity_type === "Shoot" ? "orange" : "green"
                          }
                          key={acty._id}
                        >
                          <div className="timestamp">
                            {acty.user?.name} -{" "}
                            {moment(acty._created_at).format("MM/DD/YY LT")}
                          </div>
                          {acty.text}
                        </Timeline.Item>
                      ))
                  ) : (
                    <>No Activities Yet.</>
                  )}
                </Timeline>
              </div>
            </Col>
          </Can>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  notifyClientEdit,
  updateWebSocketNote,
  updateWebSocketShoot,
})(Details);
