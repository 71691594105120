import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Avatar,
  Card,
  Row,
  Col,
  Tabs,
  Button,
  Modal,
  Input,
  Form,
  message,
  Popconfirm,
} from "antd";
import {
  UserOutlined,
  PhoneTwoTone,
  MailTwoTone,
  LockOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import Loading from "../../../shared/loading/Loading.js";
import { renderIconLine } from "../../../shared/utils/utils";
import {
  fetchClient,
  fetchClientShoots,
  fetchPaymentMethods,
  savePaymentMethod,
  removePaymentMethod,
  fetchClientStats,
  updateClient,
} from "../../../redux/action/clientAction";
import { fetchClientInvoices } from "../../../redux/action/invoiceAction";
import { deleteClientUser } from "../../../redux/action/authAction.js";
import { fetchClientCredits } from "../../../redux/action/creditAction";
import { fetchEntityNotes } from "../../../redux/action/note";
import ClientShoots from "./ClientShoots.js";
import ClientInvoices from "./ClientInvoices.js";
import ClientCredits from "./ClientCredits.js";
import PaymentMethods from "./PaymentMethods";
import Overview from "./Overview";
// import Notes from "../Order/Common/Notes";
import ClientNotes from "./ClientNotes.js";
import "./ClientDetails.scss";
import queryString from "query-string";
import { useHistory } from "react-router";
import { changeClientPassword } from "../../../redux/action/authAction.js";
import { fetchBrokerages } from "../../../redux/action/brokerageAction";
import { upload } from "../../../shared/s3Bucket/s3Service.js";
import ClientForm from "./ClientForm.jsx";

const { TabPane } = Tabs;

const ClientDetails = ({
  match,
  fetchClient,
  fetchClientShoots,
  fetchClientInvoices,
  fetchClientCredits,
  fetchBrokerages,
  updateClient,
  fetchPaymentMethods,
  client: { selectedClient, paymentMethods, clientShoots, stats, isLoading },
  credit: { clientCredits },
  brokerage: { brokerages },
  fetchEntityNotes,
  note: { notes },
  invoice: { invoices },
  auth: { user },
  savePaymentMethod,
  removePaymentMethod,
  fetchClientStats,
  changeClientPassword,
  deleteClientUser,
}) => {
  useEffect(() => {
    fetchClient(match.params.id);
    fetchEntityNotes(match.params.id);
    fetchClientInvoices(match.params.id);
    fetchClientShoots(match.params.id);
    fetchPaymentMethods(match.params.id);
    fetchClientCredits(match.params.id);
    fetchClientStats(match.params.id);
  }, [fetchEntityNotes, updateClient]); // eslint-disable-line react-hooks/exhaustive-deps

  const [active, setActive] = useState({ activeTab: "" });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [updatedClient, setUpdatedClient] = useState();
  const [password, setPassword] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const history = useHistory();
  const setTabBasedOnParam = () => {
    let tab = user?.access.includes("UBERADMIN") ? "Overview" : "Shoots";
    const value = queryString.parse(history.location.search);
    if (value.tab) {
      tab = value.tab;
      setActive({ ...active, activeTab: tab });
    } else {
      setActive({ ...active, activeTab: tab });
    }
  };
  useEffect(() => {
    window.onpopstate = (e) => {
      setTabBasedOnParam();
    };
    if (!brokerages || !brokerages.length) {
      fetchBrokerages();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTabBasedOnParam();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (password) {
      const reset = async () => {
        const ClientProfile = await changeClientPassword(selectedClient?.user, {
          password: password,
          id: selectedClient?.user,
          person: user.name,
        });
        if (ClientProfile.data) {
          message.success("Password reset successfully");
        } else {
          message.error("Oops! Something went wrong. Please try again.");
        }
      };
      reset();
    }
  }, [password]); // eslint-disable-line react-hooks/exhaustive-deps

  const [form] = Form.useForm();
  const changeTab = (activeKey) => {
    const params = new URLSearchParams();
    params.append("tab", activeKey);
    history.push({ search: params.toString() });
    setActive({
      activeTab: activeKey,
    });
  };

  const submitHandler = async (type, body) => {
    const reqBody = { ...body };
    if (typeof reqBody.headshot_url === "object") {
      const imageUrl = await upload(reqBody.headshot_url);
      reqBody.headshot_url = imageUrl[0];
    }
    const res = await updateClient(reqBody._id, reqBody);
    setUpdatedClient(res.data.client);
    return res;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsEditModalVisible(false);
  };

  const onFinish = (values) => {
    setPassword(values.password);
    setIsModalVisible(false);
    form.resetFields();
  };

  const showPopconfirm = () => {
    setOpenConfirm(true);
  };
  const handleDeleteClient = async () => {
    setConfirmLoading(true);
    try {
      const res = await deleteClientUser(selectedClient?._id);
      if (res.data) {
        message.success("Client deleted successfully");
        await fetchClient(match.params.id);
      } else {
        message.error("Oops! Something went wrong. Please try again.");
      }
      setOpenConfirm(false);
      setConfirmLoading(false);
    } catch (error) {
      message.error("Oops! Something went wrong. Please try again.");
      setOpenConfirm(false);
      setConfirmLoading(false);
    }
  };

  const renderPopconfirmText = () => {
    return (
      <div style={{ maxWidth: 400 }}>
        <h5>Are you sure you want to delete this client?</h5>
        <p>Please be aware of the following:</p>
        <ul>
          <li>Account deletion is irreversible.</li>
          <li>
            All data, including shoot history and media associations, will be
            permanently removed.
          </li>
          <li>
            Outstanding invoices and obligations should be settled before
            proceeding.
          </li>
          <li>
            After successful deletion, please email the client to inform them of
            the account deletion.
          </li>
          <li>
            Shoots, invoices and media that were previously associated with the
            client will now display "Anonymous User"
          </li>
        </ul>
        <p>
          If you have any concerns or outstanding payments, please contact the
          client and resolve that before account deletion.
        </p>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div>
        {isLoading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
        {!isLoading && selectedClient && (
          <Row className="p-3">
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Card>
                <Row justify="space-around" align="middle">
                  <Col flex={1}>
                    {(updatedClient && updatedClient.headshot_url) ||
                    selectedClient.headshot_url ? (
                      <Avatar
                        size={90}
                        src={
                          updatedClient
                            ? updatedClient.headshot_url
                            : selectedClient.headshot_url
                        }
                      />
                    ) : (
                      <Avatar size={90} icon={<UserOutlined />} />
                    )}
                  </Col>
                  <Col flex={4}>
                    <span className="client-name">
                      {`${
                        updatedClient
                          ? updatedClient.first
                          : selectedClient.first
                      } ${
                        updatedClient ? updatedClient.last : selectedClient.last
                      }`}{" "}
                      <Button
                        type="link"
                        size="small"
                        onClick={() => setIsEditModalVisible(true)}
                      >
                        <EditOutlined /> Edit
                      </Button>
                    </span>
                    <br />
                    <span
                      style={{
                        color: "#112350",
                        fontSize: "1em",
                      }}
                    >
                      {updatedClient ? updatedClient.role : selectedClient.role}
                      <br />
                      HSF#{" "}
                      {updatedClient ? updatedClient.hsf : selectedClient.hsf}
                    </span>
                  </Col>
                  <Col>
                    <Button
                      style={{
                        color: "#112350",
                        fontSize: "1em",
                      }}
                    ></Button>
                    {(user?.access.includes("ADMIN") ||
                      user?.access.includes("COMMS")) && (
                      <Button type="link" onClick={showModal}>
                        <LockOutlined /> Reset Password
                      </Button>
                    )}
                    <Modal
                      title="Reset Password"
                      visible={isModalVisible}
                      onCancel={handleCancel}
                      footer={null}
                    >
                      <Row gutter={16} justify="center">
                        <Form form={form} onFinish={onFinish}>
                          <Col>
                            <div className="mb-3 text-muted">
                              Enter the new password below. An email containing
                              this password will be sent to the client. (min. 8
                              characters)
                            </div>
                            <Form.Item
                              label="Password"
                              name="password"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your password.",
                                },
                                {
                                  min: 8,
                                  message:
                                    "The password must be at least 8 characters long.",
                                },
                              ]}
                            >
                              <Input.Password />
                            </Form.Item>
                          </Col>
                          <Col style={{ paddingLeft: "60px", marginTop: 20 }}>
                            <Form.Item>
                              <Button
                                // style={{ margin: "0 10px" }}
                                className="mr-2"
                                type="primary"
                                htmlType="submit"
                              >
                                Submit
                              </Button>
                              <Button
                                // style={{ margin: "0 10px" }}
                                type="default"
                                onClick={handleCancel}
                              >
                                Cancel
                              </Button>
                            </Form.Item>
                          </Col>
                        </Form>
                      </Row>
                    </Modal>
                    <Modal
                      visible={isEditModalVisible}
                      onCancel={handleCancel}
                      footer={null}
                    >
                      <ClientForm
                        mode="Edit"
                        initVal={updatedClient ? updatedClient : selectedClient}
                        brokerages={brokerages}
                        close={handleCancel}
                        submitHandler={(values) =>
                          submitHandler("Edit", values)
                        }
                      />
                    </Modal>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col span={24}>
                    <div className="inner-card mt-2">
                      <div>
                        {renderIconLine(MailTwoTone, selectedClient.email)}
                        {renderIconLine(PhoneTwoTone, selectedClient.phone)}
                      </div>
                      {selectedClient.referred_by &&
                        (<br />)`Referred By: ${selectedClient.referred_by}`}
                      <div className="shoot-summary">
                        <h4>Shoot Summary</h4>
                        <div className="shoot-summary-item">
                          Pending{" "}
                          <span className="float-right">
                            {stats?.shoots?.pendingShoots}
                          </span>
                        </div>
                        <div className="shoot-summary-item">
                          Scheduled{" "}
                          <span className="float-right">
                            {stats?.shoots?.scheduledShoots}
                          </span>
                        </div>
                        <div className="shoot-summary-item">
                          Processing{" "}
                          <span className="float-right">
                            {stats?.shoots?.processingShoots}
                          </span>
                        </div>
                        <div className="shoot-summary-item">
                          Completed{" "}
                          <span className="float-right">
                            {stats?.shoots?.completedShoots}
                          </span>
                        </div>
                        <div className="shoot-summary-item">
                          Rescheduled{" "}
                          <span className="float-right">
                            {stats?.shoots?.rescheduledShoots}
                          </span>
                        </div>
                        <div className="shoot-summary-item">
                          Cancelled{" "}
                          <span className="float-right">
                            {stats?.shoots?.cancelledShoots}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
              {user && (
                <>
                  <ClientNotes
                    clientId={selectedClient._id}
                    userId={user._id}
                    notes={notes}
                  />
                </>
              )}
              {user?.access.includes("ADMIN") && (
                <div className="mt-3">
                  <Popconfirm
                    title={renderPopconfirmText()}
                    open={openConfirm}
                    onConfirm={handleDeleteClient}
                    okButtonProps={{
                      loading: confirmLoading,
                    }}
                    okText="Yes, delete"
                    onCancel={() => setOpenConfirm(false)}
                    icon={
                      <QuestionCircleOutlined
                        style={{
                          color: "red",
                        }}
                      />
                    }
                  >
                    <Button
                      danger
                      block
                      type="link"
                      size="large"
                      onClick={showPopconfirm}
                    >
                      DELETE ACCOUNT
                    </Button>
                  </Popconfirm>
                </div>
              )}
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <Tabs
                type="card"
                className="ml-2 card-tabs client-tabbed-view"
                tabBarStyle={{}}
                size="large"
                activeKey={active.activeTab}
                onChange={changeTab}
              >
                {/* <Card> */}
                {user?.access?.includes("UBERADMIN") && (
                  <TabPane tab="Overview" key="Overview">
                    {active.activeTab === "Overview" && (
                      <Overview
                        updateClient={updateClient}
                        stats={stats}
                        selectedClient={selectedClient}
                        updatedClient={updatedClient}
                        setUpdatedClient={setUpdatedClient}
                      />
                    )}
                  </TabPane>
                )}
                <TabPane tab="Shoots" key="Shoots">
                  {active.activeTab === "Shoots" && (
                    <ClientShoots
                      clientShoots={clientShoots}
                      selectedClient={selectedClient}
                      isLoading={isLoading}
                    />
                  )}
                </TabPane>
                <TabPane tab="Invoices" key="Invoices">
                  {active.activeTab === "Invoices" && (
                    <ClientInvoices
                      clientInvoices={invoices}
                      isLoading={isLoading}
                      selectedClient={selectedClient}
                    />
                  )}
                </TabPane>
                <TabPane tab="Credits" key="Credits">
                  {active.activeTab === "Credits" && (
                    <ClientCredits
                      clientCredits={clientCredits}
                      isLoading={isLoading}
                      selectedClient={selectedClient}
                    />
                  )}
                </TabPane>
                <TabPane tab="Payment Methods" key="Payment-Methods">
                  {active.activeTab === "Payment-Methods" && (
                    <PaymentMethods
                      selectedClient={selectedClient}
                      cards={paymentMethods}
                      savePaymentMethod={savePaymentMethod}
                      removePaymentMethod={removePaymentMethod}
                    />
                  )}
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  client: state.clients,
  credit: state.credit,
  note: state.note,
  auth: state.auth,
  invoice: state.invoice,
  brokerage: state.brokerages,
});

export default connect(mapStateToProps, {
  fetchClient,
  fetchClientShoots,
  fetchClientCredits,
  fetchBrokerages,
  fetchPaymentMethods,
  fetchClientInvoices,
  fetchEntityNotes,
  savePaymentMethod,
  removePaymentMethod,
  updateClient,
  fetchClientStats,
  changeClientPassword,
  deleteClientUser,
})(ClientDetails);
